import {
  EntityAdminPageWS,
  Text,
  useCurrenciesContext,
  useFeeCurrenciesFilter,
  useMarketFeeModesFilter,
  useSymbolsFilter,
  type FeeTier,
} from '@talos/kyoko';
import { Fragment } from 'react';
import { useCurrentFeeTier } from './useCurrentFeeTier';

export const FeeTiers = () => {
  const marketFeeModesFilter = useMarketFeeModesFilter();
  const symbolFilter = useSymbolsFilter();
  const { currenciesList } = useCurrenciesContext();
  const feeCurrenciesFilter = useFeeCurrenciesFilter(currenciesList.map(currency => currency.Symbol));
  const currentFeeTier = useCurrentFeeTier();

  return (
    <EntityAdminPageWS<FeeTier>
      wsRequest={{ name: 'FeeTier', tag: 'FEE_TIERS_BLOTTER' }}
      title="Fee Tiers"
      subtitle={
        <Fragment>
          View your Fee Tiers here.{' '}
          {currentFeeTier ? (
            <Fragment>
              Your Current Fee Tier is: <Text color="colorTextAttention">{currentFeeTier}</Text>
            </Fragment>
          ) : null}
        </Fragment>
      }
      entityName="Fee Tier"
      entityIDField="Tier"
      childIDField="Symbol"
      filterableProperties={[symbolFilter, marketFeeModesFilter, feeCurrenciesFilter]}
      columns={[
        { type: 'text', field: 'Tier', hide: true, sort: '+' },
        { type: 'security', field: 'Symbol', hide: true, sort: '+' },
        { type: 'bps', field: 'Fee', width: 120 },
        { type: 'feeMode', field: 'FeeMode' },
        { type: 'currency', field: 'FeeCurrency' },
      ]}
      entitySearchKeys={['Tier', 'FeeMode', 'FeeCurrency', 'Symbol']}
      groupColumnDef={{
        headerName: 'Name',
        valueFormatter: ({ context, value }) => {
          const security = context.current.securitiesBySymbol?.get(value);
          return security?.DisplaySymbol ?? value;
        },
      }}
      persistKey="fee-tiers"
    />
  );
};
